* {
    margin: 0;
    padding: 0;
}

.marginTopBottom {
    margin: 20px 0px
}

.flex {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    margin: auto;
    text-align: center;
}

.home {
    width: 100%;
    box-sizing: content-box;
}

.portfolioheader {
    background: url('../../assets/images/reactbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    font-size: 10vw;
    display: flex;
    align-items: center;
}

.portfolioheaderText {
    font-size: 5vw !important;
    padding-left: 30px;
}

.blue {
    color: #4C99F0;
    text-decoration: none;
}

.typography {
    font-size: 50px;
    font-family: 'Playfair Display';
    letter-spacing: 5;
    color: white;
}

.box {
    margin: 100px 30px;
    clear: both;
}

/* About */
.skillsCard1 {
    box-shadow: 1px 1px 37px -15px rgba(0, 0, 0, 0.63) !important;
    border-bottom: 3px solid rgb(76, 143, 203) !important;
    height: 150px;
    text-align: center;
    padding: 5px;
    max-width: 350px !important;
}

.skillsCard2 {
    box-shadow: 1px 1px 37px -15px rgba(0, 0, 0, 0.63) !important;
    border-bottom: 3px solid rgb(203, 101, 76) !important;
    height: 150px;
    text-align: center;
    padding: 5px;
    max-width: 350px !important;
}

.skillsCard3 {
    box-shadow: 1px 1px 37px -15px rgba(0, 0, 0, 0.63) !important;
    border-bottom: 3px solid rgb(207, 142, 39) !important;
    height: 150px;
    padding: 5px;
    text-align: center;
    max-width: 350px !important;
}

.skillsCard4 {
    box-shadow: 1px 1px 37px -15px rgba(0, 0, 0, 0.63) !important;
    border-bottom: 3px solid rgb(142, 203, 76) !important;
    height: 150px;
    text-align: center;
    padding: 5px;
    max-width: 350px !important;
}

.aboutYellowCard {
    background: #F9BF3F !important;
    min-height: 170px;
    display: flex;
    align-items: center !important;
    padding-left: 20px;
    box-sizing: border-box;
}

.aboutYellowCardText {
    font-size: 2vw;
}

.icon {
    font-size: 20px;
    ;
}

/* experience */
.experience {
    padding: 20px;
    margin-left: 10px;
    background: #F2F3F7;
    position: relative;
    margin-bottom: 10px;
}

.horizontalLine {
    width: 2px;
    margin-left: 5px;
    background-color: #F2F3F7;
    height: 110px;
}

.experience:before {
    content: '';
    position: absolute;
    top: 5px;
    left: -10px;
    width: 0px;
    height: 0px;
    border-right: 10px solid #F2F3F7;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}

.root {
    min-height: 40px !important;
}

.root::before {
    flex: 0;
    display: none;
}

.marginLeft {
    margin-left: 30px !important;
}

/* Hexagon */
.hexa {
    margin-left: 50px;
    overflow-x: visible !important;
}

.hexagon {
    position: relative;
    width: 7.5px;
    height: 12.5px;
    background: #4C99F0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: visible !important;
}

.hexagon:before {
    content: '';
    position: absolute;
    top: 0;
    left: -3.75px;
    width: 0px;
    height: 0px;
    border-right: 3.75px solid #4C99F0;
    border-top: 6.25px solid transparent;
    border-bottom: 6.25px solid transparent;
    overflow-x: visible !important;
}

.hexagon:after {
    content: '';
    position: absolute;
    top: 0;
    right: -3.75px;
    width: 0px;
    height: 0px;
    border-left: 3.75px solid #4C99F0;
    border-top: 6.25px solid transparent;
    border-bottom: 6.25px solid transparent;
    overflow-x: visible !important;
}

/* skills */
.library {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    padding: 2px;
    background-color: white;
}

/* work */
.folio1:hover .folio_img::after {
    transform: scale(1);
}

.folio1:hover .folio_img img {
    transform: scale(1.3, 1.3);
    transition: transform 1s;
}

.folio1 {
    position: relative;
}

.folio_text {
    position: absolute;
    top: 40%;
    left: 20%;
    bottom: 40%;
    color: white;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0;
}

.folio_img img {
    height: 300px;
    width: 100%;
}

.folio_img {
    position: relative;
    transition: transform 2s;
    overflow: hidden;
}

.folio_img::after {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scale(0);
    transition: transform 300ms;
    background-color: #2525256b;
}

.folio1:hover .folio_img::after {
    transform: scale(1);
}

.folio1:hover .folio_img img {
    transform: scale(1.3, 1.3);
    transition: transform 1s;
}

.folio_text i:hover {
    color: white;
    cursor: pointer;
    transition: color 400ms;
}

.folio1:hover .folio_text {
    opacity: 1;
}

/* contact */
.contact {
    background: rgb(152, 221, 238);
    background: linear-gradient(0deg, rgba(237, 242, 243, 1) 0%, rgba(152, 221, 238, 1) 100%);
    min-height: 250px;
    text-align: center;
    max-width: 350px;
    box-sizing: border-box;
    padding-top: 20px;
}

.contactIcon {
    margin: auto;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background: rgb(152, 221, 238);
    background: linear-gradient(0deg, rgba(237, 242, 243, 1) 0%, rgba(152, 221, 238, 1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactUsTitle {
    background: linear-gradient(to right, #070352,
            #3709dc, #2390cf, #84cfe2);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.contactDetail {
    font-size: 1.5vw;
}

.button {
    display: flex;
    justify-content: center;
}

.buttonTheme a {
    color: white
}

.buttonTheme:hover {
    color: #2390cf !important;
    background-color: white !important;
    border: 1px solid #2390cf !important;
}

.buttonTheme a:hover {
    color: #2390cf !important;
}

.skillsCard {
    box-shadow: 1px 1px 37px -15px rgba(0, 0, 0, 0.63) !important;
    height: 150px;
    max-width: 350px !important;
    padding: 20px;
    text-align: center !important;
}

.skillsCard img {
    width: 60px;
    height: 60px;
    margin-top: 15px;
}

.list {
    width: 95%;
}

.education {
    font-size: 1.5vw;
}

.card {
    margin: 10px !important;
    max-width: 350px;
}

.form {
    padding: 20px !important;
    width: 500px;
}

.hideOnWebview {
    display: none;
}

@media only screen and (max-width:768px) {
    .list {
        width: 90%;
    }

    .contactDetail {
        font-size: 3vw;
    }

    .education {
        font-size: 3vw;
    }

    .form {
        margin: 10px 0 !important;
        max-width: 350px;
    }

    .card {
        margin: 10px 0 !important;
    }

    .webView {
        padding: 16px;
    }
}

@media only screen and (max-width:425px) {
    .list {
        width: 85%;
    }

    .portfolioheaderText {
        font-size: 7.5vw !important;
    }

    .aboutYellowCardText {
        font-size: 5vw;
    }

    .contactDetail {
        font-size: 3.5vw !important;
    }

    .education {
        font-size: 4vw !important;
    }

    .showOnWebview {
        display: none !important;
    }

    .hideOnWebview {
        display: block;
    }

    .marginLeft {
        margin-left: 10px !important;
    }
}

@media only screen and (min-width:769px) {
    .webView {
        padding: 30px;
    }

    .alignItems {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}